import Image from "next/image";
import React from "react";

export default function WeWorkWith() {
  return (
    <section
      id="nosotros"
      className="relative z-20 flex items-center justify-center py-10 overflow-hidden bg-neutral-200 wow animate__animated animate__fadeIn"
    >
      <div className="relative w-full px-5 ">
        <div className="max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
          <div className="relative flex flex-col items-center justify-center gap-5 md:flex-row">
            <div className="z-50 max-w-xl mx-auto lg:mx-0">
              <h2 className="text-gray-700 text-xl lg:text-3xl">
                Trabajamos con:
              </h2>
            </div>
            <div className="grid items-center max-w-4xl grid-cols-4 gap-5 mx-auto lg:mx-0 lg:pl-8">
              <Image
                className="object-contain object-center w-full h-24 p-3 bg-gray-100 border border-gray-200 rounded-lg"
                src="/images/technologies/adp.png"
                sizes="(max-width: 768px) 15vw, (max-width: 1200px) 50vw, 33vw"
                alt="Tuple"
                width={200}
                height={200}
              />
              <Image
                className="object-contain object-center w-full h-24 p-3 bg-gray-100 border border-gray-200 rounded-lg"
                src="/images/technologies/oracle.png"
                sizes="(max-width: 768px) 15vw, (max-width: 1200px) 50vw, 33vw"
                alt="Reform"
                width={200}
                height={200}
              />
              <Image
                className="object-contain object-center w-full h-24 p-3 bg-gray-100 border border-gray-200 rounded-lg"
                src="/images/technologies/quickbooks.png"
                sizes="(max-width: 768px) 15vw, (max-width: 1200px) 50vw, 33vw"
                alt="SavvyCal"
                width={200}
                height={200}
              />
              <Image
                className="object-contain object-center w-full h-24 p-3 bg-gray-100 border border-gray-200 rounded-lg"
                src="/images/technologies/run.png"
                sizes="(max-width: 768px) 15vw, (max-width: 1200px) 50vw, 33vw"
                alt="Laravel"

                width={200}
                height={200}
              />

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
