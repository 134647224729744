import Image from "next/image";
import React from "react";
import { Interweave } from "interweave";
import { polyfill } from "interweave-ssr";
import PropTypes from "prop-types";
import Link from "next/link";

export default function ServicesModal({ item, state, subject }) {
  polyfill();
  return (
    <>
      <div style={{ animationDuration: "400ms" }} className="fixed  z-[999]   top-0 animate__animated animate__fadeIn w-screen ">
        <div
          className="relative z-0 flex items-center justify-center w-screen min-h-screen px-5 py-10 overflow-hidden bg-cover md:py-20 "
        >
          <Image fill src={item.image} alt={item.name} sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" className="object-cover -z-50" />
          <div
            className="absolute w-[100vw] md:w-[70vw] left-0 h-full -z-10"
            style={{
              background:
                "linear-gradient(90deg, rgba(255,255,255,1) 46%, rgba(255,255,255,0) 100%)",
            }}
          />
          <div className="fixed top-0 right-0 z-50 p-10 animate__animated animate__fadeIn">
            <button className="font-bold text-gray-700" onClick={() => { state(false); }}>Cerrar</button>
          </div>
          <div className="px-5 py-10 md:py-20">
            <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div>
                  <div className="">
                    <h2 className="mt-2 uppercase md:mt-0 text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold">
                      {item.name}
                    </h2>
                    <p className="mt-3 mb-10 text-lg font-bold max-w-prose text-blue-light">
                      {item.intro}
                    </p>
                    <div className="mb-10 text-sm max-w-prose md:text-base ">
                      <Interweave content={item.description} />
                    </div>

                    <Link
                      className="relative z-20 inline-block text-sm font-medium text-white group focus:outline-none focus:ring "
                      href="/#contacto"
                      onClick={() => { state(false); subject(item.name) }}
                    >
                      <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 rounded-md bg-yellow-dark group-hover:translate-y-2 group-hover:translate-x-2"></span>
                      <span className="relative flex items-center px-8 py-3 rounded-md font-bold bg-blue-light gap-x-2">
                        Solicitar Servicio
                        <Image
                          width={100}
                          height={100}
                          src="/icons/arrow.svg"
                          className="object-contain w-6 h-6"
                          alt="Flecha de Contacto"
                        />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
ServicesModal.propTypes = {
  item: PropTypes.any,
  state: PropTypes.any,
  subject: PropTypes.any,
};
