import Image from "next/image";
import React, { useState } from "react";
import MelanieRodriguez from "./MelanieRodriguez";
import MariaGabriela from "./MariaGabriela";

export default function OurTeam() {
  const [maria, setMaria] = useState(false);
  const [melanie, setMelanie] = useState(false);
  return (
    <>
      {melanie && <MelanieRodriguez state={setMelanie} />}
      {maria && <MariaGabriela state={setMaria} />}

      <section
        id="equipo"
        className="relative flex items-center justify-center px-5 py-10 bg-fixed md:py-20 "
        style={{
          backgroundImage: "url(/icons/actonis-icon-3.png)",
          backgroundRepeat: "repeat",
          backgroundSize: 150,
          backgroundPosition: "center top",
        }}
      >
        <div
          className="absolute w-full h-full -z-0"
          style={{
            background:
              "linear-gradient(90deg, rgba(255,255,255,1) 40%, rgba(9,9,9,0) 100%)",
          }}
        />
        <div
          className="absolute w-full h-full -z-0"
          style={{
            background:
              "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(9,9,9,0) 50%)",
          }}
        ></div>
        <span
          data-wow-duration="1s"
          className="w-96 h-96 rounded-full absolute top-0 left-0 -translate-x-[45%] -translate-y-20 z-10  hidden md:block bg-yellow-dark animate__animated animate__fadeIn wow"
        />
        <span
          data-wow-duration="1s"
          data-wow-delay="0.3s"
          className="w-96 h-96 rounded-full  absolute top-0 left-0 -translate-x-[35%] -translate-y-32 z-10 hidden md:block  border-4 border-gray-lighter animate__animated animate__fadeIn wow"
        />
        <div className="relative z-20 w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
          <div
            data-wow-duration="1s"
            className="flex flex-col items-center justify-center animate__animated animate__fadeIn wow"
          >
            <Image
              width={500}
              height={500}
              alt="Logo de Actionis con icono de equipo"
              src="/icons/our-team-actonis.svg"
              className="object-contain w-16 md:w-24 md:mb-10 "
            />
            <h2 className="mt-2 uppercase md:mt-0 text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold">NUESTRO EQUIPO</h2>
            <p className="mt-3 mb-10 text-lg max-w-prose text-blue-light font-bold">
              Trayectoria y experiencia
            </p>
            <p className="max-w-4xl mb-20 text-sm text-center md:text-base ">
              Somos profesionales con larga trayectoria y profundo entendimiento
              de las necesidades personales y corporativas a nivel contable,
              impositivo y de branding de nuestros clientes.
            </p>
          </div>
          <div className="max-w-4xl mx-auto ">
            <div className="grid w-full grid-cols-2 gap-5 md:gap-10 ">
              <div
                onClick={() => setMaria(true)}
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                className="py-5 cursor-pointer md:py-10 bg-blue-light swiper-slide rounded-2xl animate__animated animate__fadeIn wow"
              >
                <div className="flex flex-col items-center justify-center px-5 text-white md:flex-row gap-x-10">
                  <Image
                    width={500}
                    height={500}
                    sizes="(max-width: 768px) 10vw, (max-width: 1200px) 50vw, 33vw"
                    alt="Maria Gabriela Gorrin"
                    src="/images/magabriela.jpg"
                    className="object-cover w-16 h-16 mb-2 rounded-full md:w-24 md:h-24 md:mb-0"
                  />
                  <div className="flex flex-col">
                    <p className="flex text-base text-center whitespace-nowrap font-bold md:text-left md:text-lg">
                      Maria <span className="block md:hidden">&nbsp;G.&nbsp; </span>
                      <span className="hidden md:block">&nbsp;Gabriela&nbsp;</span> Gorrin
                    </p>

                    <div className="flex items-center justify-center text-sm underline cursor-pointer gap-x-2 md:justify-start mt-3">
                      <span className="hidden sm:inline">
                        Continuar leyendo
                      </span>
                      <Image
                        width={500}
                        height={500}
                        alt="flecha"
                        src="/icons/arrow-2.svg"
                        className="object-contain w-4 h-4 mt-5 md:mt-0 "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setMelanie(true)}
                data-wow-duration="1s"
                data-wow-delay="0.4s"
                className="py-5 cursor-pointer md:py-10 bg-blue-light swiper-slide rounded-2xl animate__animated animate__fadeIn wow"
              >
                <div className="flex flex-col items-center justify-center px-5 text-white md:flex-row gap-x-10">
                  <Image
                    width={200}
                    height={200}
                    alt="Melanie Rodriguez"
                    sizes="(max-width: 768px) 10vw, (max-width: 1200px) 50vw, 33vw"
                    src="/images/melanie.jpg"
                    className="object-cover w-16 h-16 mb-2 rounded-full md:w-24 md:h-24 md:mb-0"
                  />
                  <div className="flex flex-col">
                    <p className="text-base text-center whitespace-nowrap font-bold md:text-left md:text-lg">
                      Melanie Rodriguez
                    </p>

                    <div className="flex items-center justify-center text-sm underline cursor-pointer gap-x-2 md:justify-start mt-3">
                      <span className="hidden sm:inline">
                        Continuar leyendo
                      </span>
                      <Image
                        width={50}
                        height={50}
                        alt="flecha"
                        src="/icons/arrow-2.svg"
                        className="object-contain w-4 h-4 mt-5 md:mt-0 "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
