import Image from "next/image";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import PropTypes from "prop-types";
export default function Testimonials({ data }) {
  const [swiper, setSwiper] = useState(0);

  if (data && data.length) {
    return (
      <>
        <section className="relative flex items-center justify-center px-5 py-10 overflow-hidden md:py-20">
          <span
            data-wow-duration="3s"
            className="w-32 h-32 rounded-full absolute top-72 left-[80%] translate-x-[60%]  hidden md:block  z-10  bg-yellow-dark animate__animated animate__fadeIn wow"
          ></span>
          <span
            data-wow-duration="3s"
            data-wow-delay="0.3s"
            className="w-32 h-32 rounded-full absolute top-72 left-[80%] translate-x-[50%] hidden md:block -translate-y-[5%]  z-10  border-4 border-gray-lighter animate__animated animate__fadeIn wow"
          ></span>
          <div className="relative z-10 w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
            <div className="flex flex-col items-center justify-center">
              <Image
                width={500}
                height={500}
                data-wow-duration="1s"
                src="/icons/testimonials-actonis.svg"
                className="object-contain w-16 wow animate__animated animate__fadeIn md:w-24 md:mb-10 animate__fadeInDown"
                alt="Icono de Testimonios de Actionis"
              />
              <h2
                data-wow-duration="1s"
                className="mt-2 font-bold uppercase md:mt-0  text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold animate__animated animate__fadeInDown wow"
              >
                TESTIMONIOS
              </h2>
              <p
                data-wow-duration="1s"
                className="mt-3 text-lg font-bold md:mb-10 max-w-prose text-blue-light animate__animated animate__fadeInDown wow"
              >
                Nuestros clientes opinan
              </p>
            </div>
            <div className="flex flex-col  md:flex-row md:h-[450px]  ">
              <div className=" w-full md:min-w-[500px] pr-3 mb-5 md:mb-0 flex items-end ">
                <div className="w-full text-gray-800">

                  <div className="relative w-full h-32 mt-5 overflow-hidden rounded-lg md:hidden">
                    <div
                      className="absolute w-full h-full -z-0"

                    />
                    <Image
                      src={data[swiper].logo}
                      className="object-contain p-2"
                      fill
                      alt={data[swiper].name}
                    />
                  </div>
                  <Image
                    width={500}
                    height={500}
                    src="/icons/testimonial.svg"
                    className="w-10 h-10 md:w-20 md:h-20"
                    alt="Grafico de Fondo decorativo"
                  />
                  {data[swiper].content && (
                    <div className="hidden text-sm md:text-base md:block animate__animated animate__fadeIn wow text-gray-800">
                      {data[swiper].content}
                    </div>
                  )}
                  <div className="flex items-center md:my-14 gap-x-5">
                    <div className="relative hidden w-16 h-16 overflow-hidden transition-all duration-200 rounded-full md:block">
                      <Image
                        src={data[swiper].avatar}
                        className="object-cover"
                        fill
                        alt={data[swiper].name}
                      />
                    </div>

                    <div>
                      <div className="hidden mb-1 text-sm font-semibold text-black testimonialName opensans-bold md:block md:text-base uppercase">
                        {data[swiper].name}
                      </div>
                      <div className="hidden text-sm font-semibold testimonialBusiness opensans-bold md:block text-blue-light md:text-base">
                        {data[swiper].company}
                      </div>
                    </div>
                  </div>
                  <div className="items-center justify-start hidden gap-x-5 md:flex">
                    <div className="image-swiper-button-prev">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-8 h-8 transition-all duration-200 rotate-180 cursor-pointer text-yellow-dark hover:text-yellow-dark/50 "
                        viewBox="0 0 34.64 22.98"
                        fill="currentcolor"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Grid">
                            <path
                              className="cls-1"
                              d="M30.13,10.65,0,10.5v2.33l29.62.05-8.27,8.27L23.18,23,34.64,11.51v0L32.82,9.69,23.13,0,21.3,1.83Z"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="image-swiper-button-next">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-8 h-8 transition-all duration-200 cursor-pointer text-yellow-dark hover:text-yellow-dark/50 "
                        viewBox="0 0 34.64 22.98"
                        fill="currentcolor"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Grid">
                            <path
                              className="cls-1"
                              d="M30.13,10.65,0,10.5v2.33l29.62.05-8.27,8.27L23.18,23,34.64,11.51v0L32.82,9.69,23.13,0,21.3,1.83Z"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden w-full mb-5 md:mb-0 md:flex md:items-end">
                <Swiper
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                  }}
                  onSlideChange={(s) => {
                    setSwiper(s.activeIndex);
                  }}
                  slidesPerView={3}
                  spaceBetween={10}
                  navigation={{
                    nextEl: ".image-swiper-button-next",
                    prevEl: ".image-swiper-button-prev",
                    disabledClass: "swiper-button-disabled",
                  }}
                  modules={[Navigation, Autoplay]}
                  className="testimonialsSlider !mt-auto  h-[325px]"
                >
                  {data.map((item, index) => (
                    <SwiperSlide
                      key={index}
                      id=""
                      data-wow-duration="1s"
                      className="!flex wow animate__animated animate__fadeIn !items-end md:justify-center swiper-slide  "
                    >
                      <div
                        style={{
                          backgroundImage: "url(/icons/actonis-icon-3.png)",
                          backgroundRepeat: "repeat",
                          backgroundSize: 80,
                          backgroundPosition: "center top",
                        }}
                        className=" w-[100px]  relative overflow-hidden h-[100px] rounded-full border-2 border-neutral-400  gap-y-0 md:max-w-[360px]  md:h-[250px] md:w-full  md:rounded-2xl testimonial-image"
                      >
                        <div
                          className="absolute w-full h-full -z-0"
                          style={{
                            background:
                              "linear-gradient(0deg, rgba(255,255,255,1) 40%, rgba(9,9,9,0) 100%)",
                          }}
                        />
                        <Image
                          src={item.logo}
                          className="object-contain p-5"
                          fill
                          alt={item.name}
                        />
                      </div>
                    </SwiperSlide>
                  ))}

                  <SwiperSlide className="flex justify-center swiper-slide" />
                  <SwiperSlide className="flex justify-center swiper-slide" />
                </Swiper>
              </div>
              <div className="text-gray-800 "></div>
              <div
                data-wow-duration="1s"
                className="block md:my-14 md:hidden wow animate__animated animate__fadeIn"
              >
                <div className="mb-1 text-sm text-black opensans-bold testimonialName md:text-base">
                  {data[swiper].content}
                </div>
                <div className="flex items-center gap-5 mt-5">
                  <div className="relative w-16 h-16 overflow-hidden rounded-full ">
                    <Image
                      src={data[swiper].avatar}
                      className="object-cover"
                      fill
                      alt={data[swiper].name}
                    />
                  </div>
                  <div>
                    <div className="mb-1 text-sm font-semibold text-black testimonialName opensans-bold md:text-base">
                      {data[swiper].name}
                    </div>
                    <div className="text-sm opensans-bold testimonialBusiness text-blue-light md:text-base">
                      {data[swiper].company}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start mt-5 gap-x-5 md:mt-0 md:hidden">
                <div className="image-swiper-button-prev">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8 transition-all duration-200 rotate-180 cursor-pointer text-yellow-dark hover:text-yellow-dark/50 "
                    viewBox="0 0 34.64 22.98"
                    fill="currentcolor"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Grid">
                        <path
                          className="cls-1"
                          d="M30.13,10.65,0,10.5v2.33l29.62.05-8.27,8.27L23.18,23,34.64,11.51v0L32.82,9.69,23.13,0,21.3,1.83Z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="image-swiper-button-next">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8 transition-all duration-200 cursor-pointer text-yellow-dark hover:text-yellow-dark/50 "
                    viewBox="0 0 34.64 22.98"
                    fill="currentcolor"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Grid">
                        <path
                          className="cls-1"
                          d="M30.13,10.65,0,10.5v2.33l29.62.05-8.27,8.27L23.18,23,34.64,11.51v0L32.82,9.69,23.13,0,21.3,1.83Z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

Testimonials.propTypes = {
  data: PropTypes.any,
};
