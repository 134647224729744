import Image from "next/image";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ServicesModal from "./ServicesModal";
import Link from "next/link";
export default function StarProducts({ data }) {
  const [service, setService] = useState(null);
  return (
    <>
      {service && <ServicesModal state={setService} item={service} />}
      <div>
        {data && data.length >= 3 && (
          <div className="relative flex items-center justify-center md:px-5 ">
            <div className="w-full mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
              <div className="grid grid-cols-1 text-white md:grid-cols-2">

                <div
                  onClick={() => setService(data[0])}
                  className="flex flex-col items-center justify-center py-10 bg-blue-light cursor-pointer"
                >
                  <span className="bg-blue-light w-[50%] absolute h-full left-0 -z-10 hidden md:block " />
                  <Image
                    width={500}
                    height={500}
                    data-wow-duration="2s"
                    data-wow-delay="0.2s"
                    src="/images/tax-preparation.png"
                    className="animate__animated animate__fadeIn wow w-[100%] object-contain  mb-5 max-h-[200px] md:max-h-[300px]"
                    alt={data[0].name}
                  />
                  <h2 className="text-lg text-center font-extrabold md:text-2xl">
                    {data[0].name}
                  </h2>
                  <div className="mt-2 mb-5 text-sm text-center px-10 md:px-24">
                    <p>{data[0].intro}</p>
                  </div>
                  <div className="relative inline-block text-sm font-bold group focus:outline-none focus:ring w-fit cursor-pointer . {{ $data->products[0]->slug . '-open' }}">
                    <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-white rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                    <span className="relative flex items-center px-8 py-3 rounded-md bg-yellow-dark text-blue-light gap-x-2">
                      Leer más
                      <Image
                        width={500}
                        height={500}
                        src="/icons/arrow-blue.svg"
                        className="object-contain w-6 h-6"
                        alt="flecha Azul"
                      />
                    </span>
                  </div>
                </div>

                <div className="grid grid-rows-1 md:grid-rows-2">

                  <div
                    onClick={() => setService(data[1])}
                    className="flex items-center justify-between px-5 py-5 md:pl-10 bg-yellow-dark cursor-pointer gap-x-4"
                  >
                    <span className="bg-yellow-dark w-[50%] absolute h-[50%] right-0 top-0 -z-10 hidden md:block" />
                    <div className="flex flex-col items-center order-2 xs:items-start gap-y-2 xs:order-none">
                      <h2 className="text-lg font-bold text-center xs:text-left md:text-2xl text-gray-800">
                        {data[1].name}
                      </h2>
                      <p className="mt-2 mb-5 text-sm md:pr-24 text-center md:text-left text-gray-800">
                        {data[1].intro}
                      </p>
                      <div className="relative inline-block text-sm font-bold text-white group focus:outline-none focus:ring w-fit cursor-pointer . {{ $data->products[1]->slug . '-open' }}">
                        <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-white rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                        <span className="relative flex items-center px-8 py-3 rounded-md bg-blue-light gap-x-2">
                          Leer más
                          <Image
                            width={100}
                            height={100}
                            src="/icons/arrow.svg"
                            className="object-contain w-6 h-6 "
                            alt="Flecha"
                          />
                        </span>
                      </div>
                    </div>
                    <Image
                      width={500}
                      height={500}
                      data-wow-duration="2s"
                      data-wow-delay="0.4s"
                      src="/images/irs-tax-relief.svg"
                      className="w-[150px] md:w-[120px] lg:w-[150px] animate__animated animate__fadeIn wow object-contain  mb-5 max-h-[300px]  order-1 xs:order-none"
                      alt={data[1].name}
                    />
                  </div>

                  <div
                    onClick={() => setService(data[2])}
                    className="flex items-center justify-between px-5 py-5 md:pl-10 bg-zinc-800 cursor-pointer gap-x-4"
                  >
                    <span className="bg-zinc-800 w-[50%] absolute h-[50%] right-0 bottom-0 -z-10 hidden md:block" />
                    <div className="flex flex-col items-center order-2 xs:items-start gap-y-2 xs:order-none">
                      <h2 className="text-lg font-bold text-center xs:text-left md:text-2xl">
                        {data[2].name}
                      </h2>
                      <p className="mt-2 mb-5 text-sm md:pr-24 text-center md:text-left">
                        {data[2].intro}
                      </p>
                      <div className="relative inline-block text-sm font-bold text-white group focus:outline-none focus:ring w-fit cursor-pointer . {{ $data->products[2]->slug . '-open' }}">
                        <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-white rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                        <span className="relative flex items-center px-8 py-3 rounded-md bg-blue-light gap-x-2">
                          Leer más
                          <Image
                            width={100}
                            height={100}
                            src="/icons/arrow.svg"
                            className="object-contain w-6 h-6 "
                            alt="flecha"
                          />
                        </span>
                      </div>
                    </div>
                    <Image
                      width={100}
                      height={100}
                      data-wow-duration="2s"
                      data-wow-delay="0.6s"
                      src="/images/bookeping.svg"
                      className="animate__animated animate__fadeIn wow w-[150px] md:w-[120px] lg:w-[150px] object-contain  mb-5 max-h-[300px]  order-1 xs:order-none"
                      alt={data[2].name}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        )}

        <div className="relative flex items-center justify-center p-5 bg-yellow-dark">
          <div className="w-full mx-auto">
            <div className="flex items-center justify-center text-gray-700  gap-x-4 text-lg font-bold">
              <div>
                <a href="tel:+17865885320" target="_blank" rel="noreferrer">
                  +1 (786) 588-5320
                </a>
              </div>
              <span className="h-[1px] w-[10px] bg-gray-700" />
              <Link target="_blank" href="https://calendly.com/melanie-actionis/30min?">Agendar llamada</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
StarProducts.propTypes = {
  data: PropTypes.any,
};
