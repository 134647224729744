import Image from "next/image";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form } from "formik";
import FormFieldAndError from "./FormFieldAndError";
import * as Yup from "yup";
import axios from "axios";
import Link from "next/link";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
export default function Contact({ subjectFromState }) {
  const [btnWait, setBtnWait] = useState(false);
  const [btnSuccess, setBtnSuccess] = useState(false);
  const router = useRouter();

  const post = (values) => {
    if (btnWait) {
      return toast.error("Lo sentimos, hubo un error", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const data = {
      name: values.Name,
      email: values.Email,
      phone: values.Phone,
      subject: values.Subject,
      message: values.Message,
    };
    setBtnWait(true);

    axios({
      method: "post",
      url: `${process.env.NEXT_PUBLIC_API}/api/v1/leads`,

      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function () {
        setBtnSuccess(true);
        router.push("/gracias");
        toast.success("Su mensaje ha sido enviado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch(function (error) {
        console.log(error);
        setBtnWait(false);

        toast.error("Lo sentimos, hubo un error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const ContactSchema = Yup.object().shape({
    Name: Yup.string()
      .required("Requerido")
      .min(5, "Debe tener al menos 5 caracteres")
      .max(75, "No debe tener mas de 75 caracteres"),

    Email: Yup.string()
      .email("Debe ser un coreo valido")
      .required("Requerido")
      .min(5, "Debe tener al menos 5 caracteres")
      .max(125, "No debe tener mas de 125 caracteres"),

    Phone: Yup.string()
      .required("Requerido")
      .min(8, "Debe contener 8 dígitos")
      .max(25, "No debe contener mas de 25 dígitos"),

    Subject: Yup.string()
      .required("Requerido")
      .min(8, "Debe tener al menos 8 caracteres")
      .max(75, "No debe tener mas de 75 caracteres"),

    Message: Yup.string()
      .required("Requerido")
      .min(15, "Debe tener al menos 15 caracteres")
      .max(1000, "No debe tener mas de 1000 caracteres"),
  });
  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <section id="contacto" className="relative ">
        <span
          data-wow-duration="2s"
          className="w-40 h-40 rounded-full absolute top-0 right-1/2 -translate-x-[100%] md:-translate-x-[50%]  translate-y-[35%]  z-10  bg-yellow-dark"
        ></span>
        <span
          data-wow-duration="2s"
          data-wow-delay="0.5s"
          className="w-40 h-40 rounded-full absolute top-0 right-1/2 -translate-x-[100%] md:-translate-x-[60%] translate-y-[45%] z-10  border-4 border-gray-lighter"
        ></span>
        <span
          data-wow-duration="2s"
          data-wow-delay
          className="w-72 h-72 rounded-full absolute  right-0 -translate-x-[20%] -translate-y-[20%]  bottom-0  z-0  border-4 border-yellow-dark"
        ></span>
        <div className="relative z-10 px-5 py-10 md:py-20">
          <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl ">
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 justify-items-stretch">
              <div data-wow-duration="1s" className="flex flex-col items-center justify-center mb-5 animate__animated animate__fadeInLeft wow lg:items-start lg:mb-0">
                <Image
                  width={200}
                  height={200}
                  data-wow-duration="1s"
                  src="/icons/contact-actonis.svg"
                  className="object-contain w-16 md:w-24 md:mb-10 "
                  alt="Logo de Actionis con icono de contacto"
                />
                <h2
                  data-wow-duration="1s"
                  className="mt-2 uppercase  text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold"
                >
                  CONTÁCTANOS
                </h2>
                <span
                  data-wow-duration="1s"
                  className="flex flex-col items-start my-10 text-sm opensans-bold md:text-base gap-y-1 "
                >
                  <span className="flex items-center justify-center gap-x-3">
                    <div className="flex ">
                      <a
                        href="tel:+17865885320"
                        className="transition-all duration-100 hover:text-blue-light whitespace-nowrap"
                      >
                        +1 (786) 588-5320
                      </a>
                    </div>
                    <div className="flex"></div>
                  </span>
                  <a
                    href="mailto:actionisconsultingcorp@gmail.com"
                    className="transition-all duration-100 hover:text-blue-light"
                  >
                    actionisconsultingcorp@gmail.com
                  </a>
                </span>
                <div
                  data-wow-duration="1s"
                  className="flex flex-col items-center justify-start gap-x-14 gap-y-5 sm:flex-row "
                >
                  <div className="flex items-center justify-center gap-x-3">
                    <Link
                      href="https://www.facebook.com/actionis.consulting.corp"
                      aria-label="facebook"
                      target="_blank"
                      className="flex items-center justify-center w-8 h-8 rounded-full bg-yellow-dark"
                    >
                      <Image
                        width={200}
                        height={200}
                        src="/icons/fb.svg"
                        className="object-contain w-[18px] h-[18px]"
                        alt="Facebook"
                      />
                    </Link>
                    <Link
                      href="https://www.instagram.com/actionis.consulting"
                      target="_blank"
                      aria-label="Instagram"
                      className="flex items-center justify-center w-8 h-8 rounded-full bg-yellow-dark"
                    >
                      <Image
                        width={200}
                        height={200}
                        src="/icons/ig.svg"
                        className="object-contain w-[18px] h-[18px]"

                        alt="Instagram"
                      />
                    </Link>
                    <Link
                      href="https://www.youtube.com/@actionisconsultingcorp.5571"
                      target="_blank"
                      className="flex items-center justify-center w-8 h-8 rounded-full bg-yellow-dark"
                    >
                      <Image
                        width={200}
                        height={200}
                        src="/icons/yt.svg"
                        className="object-contain w-[18px] h-[18px]"
                        alt="Youtube"
                        aria-label="Youtube"
                      />
                    </Link>
                  </div>
                  <Link
                    className="relative inline-block text-sm font-medium text-white group focus:outline-none focus:ring active:text-indigo-500"
                    target="_blank"
                    href="https://calendly.com/melanie-actionis/30min?"
                  >
                    <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 rounded-md bg-yellow-dark group-hover:translate-y-2 group-hover:translate-x-2" />
                    <span className="relative flex items-center px-8 py-3 rounded-md font-bold bg-blue-light gap-x-2">
                      <Image
                        width={200}
                        height={200}
                        src="/icons/phone.svg"
                        className="object-contain w-6 h-6"
                        alt="Teléfono"
                      />
                      Agendar llamada
                    </span>
                  </Link>
                </div>
                <div className="flex items-center justify-start mt-10">
                  <Image
                    width={200}
                    height={200}
                    src="/icons/contact.svg"
                    className="hidden object-contain md:block md:w-36 md:h-36"
                    alt="Contacto"
                  />
                </div>
              </div>
              <div data-wow-duration="1s" className="animate__animated animate__fadeInRight wow">
                <Formik
                  initialValues={{
                    Name: "",
                    Email: "",
                    Phone: "",
                    Subject: "",
                    Message: "",
                  }}
                  validationSchema={ContactSchema}
                  onSubmit={post}
                >
                  {({ errors, touched, }) => (
                    <Form
                      action="#"
                      noValidate
                      className="w-full mx-auto space-y-11 "
                    >
                      <FormFieldAndError
                        btnSuccess={btnSuccess}
                        errors={errors}
                        touched={touched}
                        extraClass={"mx-auto"}
                        name={"Name"}
                        placeholder="Nombre y Apellido:"
                      />
                      <FormFieldAndError
                        btnSuccess={btnSuccess}
                        errors={errors}
                        touched={touched}
                        extraClass={"mx-auto"}
                        name={"Email"}
                        placeholder="Email:"
                      />
                      <FormFieldAndError
                        btnSuccess={btnSuccess}
                        errors={errors}
                        touched={touched}
                        extraClass={"mx-auto"}
                        name={"Phone"}
                        placeholder="Teléfono:"
                      />
                      <FormFieldAndError
                        value={subjectFromState}
                        btnSuccess={btnSuccess}
                        errors={errors}
                        touched={touched}
                        extraClass={"mx-auto"}
                        name={"Subject"}
                        placeholder="Asunto:"
                      />
                      <FormFieldAndError
                        btnSuccess={btnSuccess}
                        errors={errors}
                        touched={touched}
                        extraClass={"mx-auto min-h-[200px]"}
                        name={"Message"}
                        placeholder="Mensaje:"
                        as="textarea"
                      />
                      <div className="flex flex-col items-center justify-center w-full max-w-lg mx-auto gap-y-4 lg:max-w-none md:flex-row ">
                        <div className="flex justify-center w-full max-w-lg md:justify-end lg:max-w-none">
                          <button
                            type="submit"
                            id="contactBtn"
                            className="relative inline-block text-sm font-medium text-white group focus:outline-none focus:ring w-fit "
                          >
                            <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 rounded-md bg-yellow-dark group-hover:translate-y-2 group-hover:translate-x-2"></span>
                            <div className="relative flex items-center justify-center w-full px-8 py-3 rounded-md font-bold bg-blue-light gap-x-2">
                              Enviar

                              {btnWait ?
                                <Image width={25} height={25} className="object-contain" src={"/icons/loader.gif"} />
                                :

                                <Image
                                  width={100}
                                  height={100}
                                  id="contactBtnIcon"
                                  src="/icons/arrow.svg"
                                  className="object-contain w-6 h-6 "
                                  alt="Enviar Mensaje Icono"
                                />
                              }

                            </div>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
Contact.propTypes = {
  subjectFromState: PropTypes.any,
};