import Image from "next/image";
import React from "react";

export default function Methodology() {
  return (
    <>
      <section
        id="metodologia"
        className="relative flex items-center justify-center px-5 py-10 bg-fixed -z-20 md:py-20"
        style={{
          backgroundImage: "url(/icons/actonis-icon-3.png)",
          backgroundRepeat: "repeat",
          backgroundSize: 150,
          backgroundPosition: "center top",
        }}
      >
        <div
          className="absolute w-full h-full -z-0"
          style={{
            background:
              "linear-gradient(0deg, rgb(255, 255, 255) 50%, rgba(9,9,9,0) 100%)",
          }}
        />
        <div className="relative z-40 w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
          <div className="flex flex-col items-center justify-center">
            <div
              data-wow-duration="1s"
              className="flex flex-col items-center justify-center animate__animated animate__fadeIn wow"
            >
              <Image
                width={100}
                height={100}
                src="/icons/methodology-actonis.svg"
                className="object-contain w-16 md:w-24 md:mb-10 "
                alt="Logo de Actionis con icono de metodología"
              />
              <h2 className="mt-2 uppercase md:mt-0 text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold">
                NUESTRA METODOLOGÍA
              </h2>
              <p className="mt-3 mb-10 text-lg font-bold max-w-prose text-blue-light">
                Relación con el cliente.
              </p>
              <div className="flex flex-col max-w-4xl mb-5 text-sm text-center md:mb-20 md:text-base gap-y-4 ">
                <p>
                  Nuestra METODOLOGÍA está basada en el trabajo en equipo con
                  nuestros clientes.{" "}
                </p>
                <p>
                  Con ellos establecemos una relación de comunicación directa
                  para entender sus necesidades y a partir de ese entendimiento
                  mutuo, definir los objetivos orientados a satisfacer tus
                  necesidades particulares para el logro del éxito.
                </p>
                <p>
                  Juntos logramos implementar herramientas concretas para el
                  establecimiento, crecimiento{" "}
                  <br className="hidden md:block" /> y mejoramiento estructural
                  de su negocio.
                </p>
              </div>
            </div>
            <Image
              width={2000}
              height={2000}
              src="/images/process.svg"
              className="z-10 hidden animate__animated animate__fadeIn wow w-full max-w-[80%] md:block img-meto rounded-3xl animate__animated animate__fadeIn wow "
              alt="Proceso"
            />
            <Image
              width={2000}
              height={2000}
              src="/images/process-mobile.svg"
              className="z-10 block w-full animate__animated animate__fadeInUp wow img-meto md:hidden rounded-3xl animate__fadeIn "
              alt="Proceso"
            />
          </div>
        </div>
      </section>
    </>
  );
}
