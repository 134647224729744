import React from "react";
import Marquee from "react-fast-marquee";

export default function MarqueeComponent() {
  return (
    <>
      <section
        className="relative text-lg md:text-2xl font-bold"
        style={{
          backgroundImage: "url(/icons/actonis-icon-yellow.png)",
          backgroundRepeat: "repeat",
          backgroundSize: 150,
          backgroundPosition: "center top",
        }}
      >
        <div
          className="absolute w-full h-full -z-10"
          style={{ background: "rgba(253, 189, 15, 0.973)" }}
        />
        <div className="w-full !py-1 !pt-4 my-0 cursor-pointer overflow-hidden">
          <Marquee
            pauseOnHover={true}
            autoFill={true}
            loop={0}
            speed={50}
            direction={"right"}
            className=" !text-white content flex overflow-hidden"
          >
            <span className="item">
              Análisis de negocio
            </span>
            <span className="mx-5 align-middle item">●</span>
            <span className="item">
              Registro de compañías
            </span>
            <span className="mx-5 align-middle item">●</span>
            <span className="item">Contabilidad</span>
            <span className="mx-5 align-middle item">●</span>
            <span className="item">
              Preparación de impuestos
            </span>
            <span className="mx-5 align-middle item">●</span>
          </Marquee>
        </div>
        <div className="w-full !pb-4 !py-1 my-0 cursor-pointer ">
          <Marquee
            pauseOnHover={true}
            autoFill={true}
            speed={50}
            loop={0}
            className=" !text-white content overflow-hidden"
          >
            <span className="item">
              Análisis de negocio
            </span>
            <span className="mx-5 align-middle item">●</span>
            <span className="item">
              Registro de compañías
            </span>
            <span className="mx-5 align-middle item">●</span>
            <span className="item">Contabilidad</span>
            <span className="mx-5 align-middle item">●</span>
            <span className="item">
              Preparación de impuestos
            </span>
            <span className="mx-5 align-middle item">●</span>
          </Marquee>
        </div>
      </section>
    </>
  );
}
