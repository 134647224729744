import Image from "next/image";
import React from "react";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCreative, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import PropTypes from "prop-types";
export default function Hero({ data }) {
  if (data && data.length) {
    return (
      <>
        <section id="hero" className="">
          <div className="swiper heroSwiper">
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: true,
              }}
              grabCursor={true}
              effect={"creative"}
              creativeEffect={{
                prev: {
                  shadow: true,
                  translate: [0, 0, -400],
                },
                next: {
                  translate: ["100%", 0, 0],
                },
              }}
              pagination={{
                el: ".my-custom-pagination-div",
                clickable: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination, EffectCreative, Autoplay]}
              className="heroSwiper"
            >
              {data.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="relative items-center min-h-screen px-5 bg-white sm:pt-[160px] !flex bg-center bg-no-repeat bg-cover swiper-slide min-w-screen "

                >
                  <Image fill src={item.image} alt={item.title} className="object-cover" priority={false} />
                  <div className="absolute top-0 left-0 w-full h-full" style={{
                    backgroundImage: `linear-gradient(265deg, rgba(255,255,255,0) 41%, rgba(255, 255, 255, 0.93) 83%)`,
                  }}
                  />
                  <div className="w-full h-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
                    <h2
                      data-wow-duration="1s"
                      className="relative z-30 max-w-2xl mt-5 2xl:max-w-3xl md:mt-0 wow animate__animated animate__fadeIn text-3xl md:text-5xl lg:text-6xl font-extrabold text-shadow-yellow"
                    >
                      {item.title}
                    </h2>
                    <div
                      data-wow-duration="1s"

                      className="relative z-20 my-4 text-sm text-shadow-1 md:my-9 max-w-prose md:text-lg wow animate__animated animate__fadeIn"
                    >
                      {item.description && item.description}
                      <div
                        className="absolute inset-x-0 top-[calc(100%-13rem)] blur-3xl -z-10 transform-gpu overflow-hidden  sm:top-[calc(100%-30rem)]"
                        aria-hidden="true"
                      >
                        <div
                          className="relative left-[calc(50%+3rem)]  aspect-[1155/678] w-[36.125rem] -translate-x-[80%] -translate-y-[10%] bg-gradient-to-tr from-white to-white  opacity-100 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                          style={{
                            clipPath:
                              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                          }}
                        />
                      </div>
                    </div>

                    <div className="relative z-40 flex flex-col items-start justify-start gap-5 sm:flex-row ">
                      {item.callToAction1 && (
                        <Link
                          data-wow-duration="1s"
                          className="relative inline-block text-sm font-bold text-blue-light wow animate__animated animate__fadeIn group focus:outline-none focus:ring "
                          href={item.url1}
                        >
                          <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 rounded-md bg-blue-light group-hover:translate-y-2 group-hover:translate-x-2" />
                          <span className="relative flex items-center px-8 py-3 rounded-md opensans-bold bg-yellow-dark gap-x-2">
                            {item.callToAction1}
                            <Image
                              width={50}
                              height={50}
                              src="/icons/arrow-blue.svg"
                              className="object-contain w-6 h-6"
                              alt="Flecha decorativa"
                            />
                          </span>
                        </Link>
                      )}
                      {item.callToAction2 && (
                        <Link
                          data-wow-duration="1s"

                          className="relative inline-block text-sm font-bold text-white wow animate__animated animate__fadeIn group focus:outline-none focus:ring "
                          href={item.url2}
                        >
                          <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                          <span className="relative flex items-center py-3 rounded-md opensans-bold gap-x-2 text-blue-light">
                            {item.callToAction2}
                            <svg
                              fill="currentcolor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 34.64 22.98"
                              className="w-6 h-6"
                            >
                              <g id="Layer_2" data-name="Layer 2">
                                <g id="Grid">
                                  <path
                                    className="cls-1"
                                    d="M30.13,10.65,0,10.5v2.33l29.62.05-8.27,8.27L23.18,23,34.64,11.51v0L32.82,9.69,23.13,0,21.3,1.83Z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                        </Link>
                      )}
                    </div>
                    <div
                      className="absolute bottom-0 left-0 flex items-center w-screen h-60 "
                      style={{
                        background:
                          "linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(0,212,255,0) 100%)",
                      }}
                    ></div>
                  </div>
                </SwiperSlide>
              ))}
              <div className="heroSwiperSwiperPagination  my-custom-pagination-div swiper-pagination !rotate-90 !w-fit !left-auto !bottom-1/2 translate-y-1/2 !right-11 hidden md:block  " />
            </Swiper>
          </div>
        </section>
      </>
    );
  }
}
Hero.propTypes = {
  data: PropTypes.any,
};
