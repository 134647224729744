import Image from 'next/image'
import React from 'react'
import PropTypes from "prop-types";
export default function MariaGabriela({ state }) {
  return (
    <>
      <div style={{ animationDuration: "400ms" }} className="fixed  z-[999] bg-white backdrop-blur-lg  min-h-screen w-[100vw]  justify-center items-center px-5 top-0 animate__animated animate__fadeIn overflow-y-auto">
        <div className="fixed top-0 right-0 z-50 p-10 animate__animated animate__fadeIn">
          <Image width={500} height={500} src="/icons/close.svg" onClick={() => state(false)} className="w-12 h-12 cursor-pointer " alt='' />
        </div>
        <div className="z-20 flex items-start justify-center w-full h-screen max-w-xl mx-auto overflow-y-auto md:max-w-5xl 2xl:max-w-6xl">
          <div className="w-full overflow-y-auto bg-white md:px-20">
            <div role="list" className="grid max-w-2xl grid-cols-1 mx-auto my-4 gap-x-6 gap-y-10 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
              <div className="w-full mx-auto">
                <Image width={500} height={500} className="object-cover mb-4 rounded-full w-28 h-28" src="/images/magabriela.jpg" alt='' />
                <h3 className="text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold">MARIA GABRIELLA GORRIN</h3>
                <p className="font-bold text-gray-600 mt-4 text-lg">Contadora Pública </p>
                <p className="mt-4 text-gray-600">Soy Contadora Pública colegiada en la Universidad Católica Andrés
                  Bello en Venezuela. Además, tengo un Máster en Real State Management de la Universidad
                  Metropolitana en Venezuela.</p>
                <div className="flex flex-col text-base text-justify text-gray-600 gap-y-4">
                  <p className="mt-4 text-gray-600">He recibido entrenamiento y certificaciones en las
                    siguientes áreas: </p>
                  <ul role="list" className="max-w-xl my-4 space-y-2 text-gray-600 ">
                    <li className="flex gap-x-3">
                      <svg className="flex-none w-5 h-5 mt-1 text-blue-light" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                      <span className="text-gray-600">Government Compliance.</span>
                    </li>
                    <li className="flex gap-x-3">
                      <svg className="flex-none w-5 h-5 mt-1 text-blue-light" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                      <span className="text-gray-600">Crucial Conversations.</span>
                    </li>
                    <li className="flex gap-x-3">
                      <svg className="flex-none w-5 h-5 mt-1 text-blue-light" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                      <span className="text-gray-600">Code of Conduct.</span>
                    </li>
                    <li className="flex gap-x-3">
                      <svg className="flex-none w-5 h-5 mt-1 text-blue-light" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                      <span className="text-gray-600">EQ.</span>
                    </li>
                    <li className="flex gap-x-3">
                      <svg className="flex-none w-5 h-5 mt-1 text-blue-light" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                      <span className="text-gray-600">Management Changer -
                        ADKAR.</span>
                    </li>
                    <li className="flex gap-x-3">
                      <svg className="flex-none w-5 h-5 mt-1 text-blue-light" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                      <span className="text-gray-600">Perform to Premier.</span>
                    </li>
                    <li className="flex gap-x-3">
                      <svg className="flex-none w-5 h-5 mt-1 text-blue-light" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                      <span className="text-gray-600">5 Choices to Extraordinary Productivity.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <svg className="flex-none w-5 h-5 mt-1 text-blue-light" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                      <span className="text-gray-600">Communicating for Leadership Success.
                      </span>
                    </li>
                    <li className="flex gap-x-3">
                      <svg className="flex-none w-5 h-5 mt-1 text-blue-light" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                      <span className="text-gray-600">QuickBooks Desktop &amp; Online Tax
                        Certification PTIN. </span>
                    </li>
                    <li className="flex gap-x-3">
                      <svg className="flex-none w-5 h-5 mt-1 text-blue-light" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                      </svg>
                      <span className="text-gray-600">AML advocate.</span>
                    </li>
                  </ul>
                  <p>
                    Tengo más de 25 años de experiencia en el área corporativa y he trabajado en diferentes
                    industrias tales como Real Estate, Seguros, Editorial/Música y Tecnología, desempeñándome
                    como Gerente General, Contralora, Tesorera, Directora de Crédito de Cobranza, en su mayoría
                    reconocidas a nivel mundial “500 Fortune”.
                  </p>
                  <p>
                    Durante todos mis años de experiencia, he trabajado con equipos multidisciplinarios y
                    multiculturales.
                  </p>
                  <p>
                    Mi experiencia se ha enfocado mayormente en mercados Latino y Centroamericanos, europeos y
                    de Estados Unidos. Las áreas más destacadas de mi trayectoria han sido en planificación
                    financiera y estratégica, desarrollo e implementación de políticas y procedimientos
                    organizativos y contables, contabilidad, tesorería e impuestos, siempre con un foco de
                    crecimiento y fortalecimiento de las empresas en las cuales he colaborado.
                  </p>
                  <p>
                    En este momento soy una exitosa entrepreneur y dueña de mi negocio que comencé con mi socia
                    Melanie Rodríguez hace 5 años donde prestamos servicios de planificación, contabilidad e
                    impuestos y marketing a la pequeña y mediana empresa, a personas naturales nacionales y
                    extranjeras que desean crecer en el mercado de los Estados Unidos.
                  </p>
                  <p>
                    Me mueve mi pasión por el servicio y mis deseos de apoyar a pequeños y medianos empresarios
                    a lograr sus metas y objetivos, construyendo con ellos una relación sólida fundamentada en
                    la comunicación directa y el profesionalismo.
                  </p>
                  <p>
                    Durante mi carrera he descubierto que desarrollar y fortalecer las relaciones humanas, tanto
                    con miembros de tu equipo, como con superiores y clientes es la clave del éxito.
                  </p>
                  <p>
                    Soy Miembro de EWF (Executive Women Forum) Organizational Committee of the Victory Awards
                    (Politic Marketing Education) y Member and co-founder of Creative Collaboration Circle.
                  </p>
                  <p>
                    Con todo este bagaje de experiencias y conocimientos he logrado el nivel formación que me
                    permite hoy ser emprendedora con un sueño claro:
                  </p>
                  <p className="italic">
                    Prestar un servicio profesional a mis clientes que marque la diferencia en la obtención de
                    sus objetivos.
                  </p>
                </div>
              </div>
              {/* More people... */}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

MariaGabriela.propTypes = {
  state: PropTypes.any,
};