import Image from 'next/image'
import React from 'react'
import PropTypes from "prop-types";
export default function MelanieRodriguez({ state }) {
  return (
    <>
      <div style={{ animationDuration: "400ms" }} className="fixed   z-[999] bg-white  backdrop-blur-lg   min-h-screen w-[100vw]  justify-center items-center px-5 top-0 animate__animated animate__fadeIn overflow-y-auto">
        <div className="fixed top-0 right-0 z-50 p-10 ">
          <Image width={500} height={500} src="/icons/close.svg" onClick={() => state(false)} className="w-12 h-12 cursor-pointer " alt='' />
        </div>
        <div className="z-20 flex items-start justify-center w-full h-screen max-w-xl mx-auto overflow-y-auto md:max-w-5xl 2xl:max-w-6xl">
          <div className="w-full overflow-y-auto bg-white md:px-20">
            <div role="list" className="grid max-w-2xl grid-cols-1 mx-auto my-4 gap-x-6 gap-y-10 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
              <div className="w-full mx-auto">
                <Image width={500} height={500} className="object-cover mb-4 rounded-full w-28 h-28" src="/images/melanie.jpg" alt='' />
                <h3 className="text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold">MELANIE RODRÍGUEZ</h3>
                <p className="font-bold mt-4 text-gray-600">Licenciada en Comunicación Social</p>
                <p className="my-4 text-gray-600">Soy Licenciada en Comunicación Social con énfasis en Publicidad y
                  Relaciones Públicas, y obtuve mi título en la Universidad Católica Andrés Bello de Venezuela en
                  el año 1990.
                </p>
                <div className="flex flex-col text-base text-justify text-gray-600 gap-y-4">
                  <p>
                    Cuento con más de 35 años de experiencia que me han servido para desarrollar habilidades que
                    definen mi actual mentalidad de emprendedora. Mis primeras experiencias laborales me dieron
                    la oportunidad de desarrollar habilidades en las áreas de relaciones públicas, solución de
                    conflictos, trabajo bajo presión, trabajo en equipo y comunicación entre otras; habilidades
                    que la educación académica no ofrece.
                  </p>
                  <p>
                    Luego trabajé investigación de mercado, durante este tiempo tuve oportunidad no sólo de
                    desarrollarme en el campo de la investigación de mercado, lo cual fue muy enriquecedor, sino
                    que también tuve la oportunidad de planificar y poner en marcha las operaciones de nuevas
                    oficinas de la empresa en varios países de Centro América; y manejar la División de Estudios
                    Ad-Hoc donde formé un equipo de más de 30 personas y manejé clientes del nivel de IBM,
                    Coca-Cola, Unilever, Procter &amp; Gamble, entre otros.
                  </p>
                  <p>
                    Después trabajé en varios proyectos comerciales que fueron de gran relevancia en su momento:
                    Guía y Ranking de Restaurantes de American Express, Guías Comerciales 13 centros Comerciales
                    para Banco Unión, Guía de los Niños para Banco Unión.
                  </p>
                  <p>
                    Paralelamente a estas actividades profesionales hice el “Entrenamiento de Líderes” de la
                    organización Psicoley en Venezuela. Donde descubrí habilidades que desconocía y me entrené
                    para crear y fortalecer nuevas habilidades que apoyaron mi crecimiento personal y
                    profesional.
                  </p>
                  <p>
                    Vivo en Estados Unidos desde el 2000 donde primero trabajé en el Centro Interamericano de
                    Gerencia Política coordinando todos los programas de capacitación y demás actividades de
                    esta institución, tanto en Miami como en el resto de Latinoamérica. Esta responsabilidad
                    incluyó desde la concepción de los diferentes programas, la contratación de proveedores, la
                    invitación tanto de conferencistas como de participantes, desarrollo de la imagen gráfica de
                    cada programa, el marketing, las inscripciones hasta la logística el día del evento.
                  </p>
                  <p>
                    En 2010 llego el momento de convertirme en “entrepreneur” motivada por el llamando interno
                    de superación personal e independencia, pero especialmente de trabajar bajo mis principios
                    de excelencia y mi deseo de dejar un legado. Fue allí cuando fundé Marketing Político en la
                    Red (MPR), una empresa que como principio reunía dos campos donde mi experiencia se había
                    desarrollado: la capacitación política y el manejo de redes sociales.
                  </p>
                  <p>
                    Los aprendizajes han sido infinitos y las satisfacciones indescriptibles. El propósito de
                    dejar un legado lo logré más de una vez:
                  </p>
                  <p>
                    Primero, creamos un reconocimiento que hoy en día es el más importante en la consultoría
                    política latinoamericana: los “Napolitan Victory Award”. Segundo, Conformamos el “VAC Dream
                    Team” un equipo de trabajo de jóvenes de todas las latitudes que, sin temor a equivocarme es
                    uno de mis mayores orgullos y que a la vez me dejó la necesidad de siempre aportar lo mejor
                    de mi y apoyar a las nuevas generaciones para que den y logren lo mejor de ellos.
                  </p>
                  <p>
                    Actualmente me he planteado nuevas metas profesionales y personales, que nacieron a raíz de
                    mis primeras incursiones en el campo de los negocios por internet y que me han llevado
                    buscar fuentes de información para prepararme en este campo. Lo que me encontré en esta
                    búsqueda ha sido muy valioso y mi objetivo es compartir con ustedes todos los
                    descubrimientos que he hecho a lo largo de este camino, que ha dado como resultado la
                    fundación de <span className="font-semibold">Actionis Consulting Corp</span>.
                  </p>
                </div>
              </div>
              {/* More people... */}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

MelanieRodriguez.propTypes = {
  state: PropTypes.any,
};