import Image from "next/image";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import PropTypes from "prop-types";
import Link from "next/link";
import { Autoplay } from "swiper";
export default function Posts({ data }) {
  if (data && data.length) {
    return (
      <>
        <section
          id="recursos"
          className="relative flex items-center justify-center px-5 py-10 overflow-hidden bg-fixed md:py-20"
          style={{
            backgroundImage: "url(/icons/actonis-icon-3.png)",
            backgroundRepeat: "repeat",
            backgroundSize: 150,
            backgroundPosition: "center top",
          }}
        >
          <div
            className="absolute w-full h-full -z-0 "
            style={{
              background:
                "linear-gradient(0deg, rgb(255, 255, 255) 50%, rgba(9,9,9,0) 100%)",
            }}
          />
          <span
            data-wow-duration="1s"
            data-wow-delay="2s"
            className="w-32 h-32 rounded-full absolute top-72 right-[60%] -translate-x-[60%]  hidden md:block  z-10 bottom-0 -translate-y-[80%] bg-yellow-dark animate__animated animate__fadeIn wow"
          />
          <span
            data-wow-duration="1s"
            data-wow-delay="2s"
            className="w-32 h-32 rounded-full absolute top-72 right-[60%] -translate-x-[50%] hidden md:block -translate-y-[90%] bottom-0  z-10  border-4 border-gray-lighter animate__animated animate__fadeIn wow"
          />
          <div className="relative z-10 w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl">
            <div className=" w-full md:min-w-[500px] pr-3 mb-5 md:mb-0 flex  justify-center  md:items-center md:hidden ">
              <div data-wow-duration="1s" className="flex flex-col items-center animate__animated animate__fadeIn wow md:justify-end">
                <Image
                  width={800}
                  height={800}
                  src="/icons/news-actonis.svg"
                  className="object-contain w-16 md:w-24 md:mb-10 "
                  alt="Logo de Actionis con un icono de noticias"
                />
                <h2 className="mb-10 uppercase md:mt-0 lg:mb-0 text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold">
                  Recursos
                </h2>
                <Link
                  href="/recursos"
                  className="relative inline-block text-sm font-medium text-white cursor-pointer group focus:outline-none focus:ring w-fit"
                >
                  <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-white rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                  <span className="relative flex items-center px-8 py-3 rounded-md font-bold bg-blue-light gap-x-2">
                    Ver todos
                    <Image
                      width={100}
                      height={100}
                      src="/icons/arrow.svg"
                      className="object-contain w-6 h-6 "
                      alt="flecha"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="flex flex-row ">
              <div className=" w-full md:min-w-[500px] pr-3 mb-5 md:mb-0  items-center hidden md:flex ">
                <div className="flex flex-col justify-center">
                  <Image
                    width={800}
                    height={800}
                    src="/icons/news-actonis.svg"
                    className="object-contain w-16 md:w-24 md:mb-10 "
                    alt="Logo de Actionis con un icono de noticias"
                  />
                  <h2 className="mb-10 uppercase md:mt-0 title-1 lg:mb-0 text-shadow-yellow text-3xl md:text-4xl lg:text-5xl font-extrabold">
                    Recursos
                  </h2>
                  <Link
                    href="/recursos"
                    className="relative inline-block text-sm mt-10 font-medium text-white group focus:outline-none focus:ring w-fit cursor-pointer . {{ $data->products[2]->slug . '-open' }}"
                  >
                    <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-white rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                    <span className="relative flex items-center px-8 py-3 rounded-md font-bold bg-blue-light gap-x-2">
                      Ver todos
                      <Image
                        width={100}
                        height={100}
                        src="/icons/arrow.svg"
                        className="object-contain w-6 h-6 "
                        alt="flecha"
                      />
                    </span>
                  </Link>
                </div>
              </div>

              <div className="w-full mb-5 md:mb-0 ">
                <Swiper
                  className=""
                  grabCursor={true}
                  spaceBetween={10}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                  }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },

                    767: {
                      slidesPerView: 3,
                    },
                  }}
                  modules={[Autoplay]}
                >
                  {data.map((item, index) => (
                    <SwiperSlide
                      key={index}
                      className="swiper-slide  !h-full animate__animated animate__fadeIn wow"
                    >
                      <div className="bg-blue-light group !min-h-[450px] !h-full mx-auto flex flex-col gap-y-0 items-stretch justify-between max-w-[360px] rounded-2xl rounded-tr-[70px] text-white">
                        <div className="flex flex-col justify-between h-full p-4">
                          <p className="text-sm text-white">
                            {item.date}
                          </p>
                          <h3 className="my-5 max-w-prose font-bold text-lg text-yellow-dark">{item.name}</h3>
                          <Link
                            href={`/recursos/${item.slug}`}
                            className="mb-5 underline w-fit font-xs"
                          >
                            Leer más
                          </Link>
                        </div>
                        <div className="relative w-full min-h-[208px] max-h-[208px] ">
                          <Link
                            href={`/recursos/${item.slug}`}
                            className="absolute flex items-center justify-center w-full h-full transition-all duration-200 group-hover:bg-blue-light/60"
                          >
                            <div className="items-center justify-center hidden group-hover:flex text-yellow-dark gap-x-1 ">
                              <p className="font-bold">Leer Articulo</p>
                              <Image
                                width={200}
                                height={200}
                                src="/icons/arrow-3.svg"
                                className="w-4 "
                                alt="Flecha"
                              />
                            </div>
                          </Link>
                          <Image
                            width={500}
                            height={500}
                            src={item.cover}
                            className="object-cover w-full h-52 -z-10"
                            alt={item.name}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                  <SwiperSlide className="justify-center  swiper-slide !hidden md:!block" />
                  <SwiperSlide className="justify-center  swiper-slide !hidden md:!block" />
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

Posts.propTypes = {
  data: PropTypes.any,
};
