import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";

const FormFieldAndError = ({
  btnSuccess,
  errors,
  touched,
  name,
  placeholder,
  extraClass,
  as,
  options,
  errorColor
}) => {
  return (
    
    <>

      {as == "select" && (
          <div className="relative ">
            <Field
              autoComplete="off"
              as={as}
              type="text"
              {...(btnSuccess ? { value: "", readOnly: true } : {})}
              className={`w-full bg-zinc-100 border-0 outline-none  px-5 py-3 opensans-bold placeholder-gray-600 rounded-md max-w-lg lg:max-w-none  ${btnSuccess&& "pointer-events-none"}  ${extraClass} ${
                errors[name] && touched[name] && !btnSuccess
                  ? "border border-red-600"
                  : "border border-gray-400 "
              }`}
              placeholder={placeholder}
              name={name}
            >
              {options.map((item, key) => (
                <option key={key} value={item.value}>
                  {item.option}
                </option>
              ))}
            </Field>
            {errors[name] && touched[name] && !btnSuccess ? (
              <p className="ml-5 text-sm font-semibold text-red-600">
                {errors[name]}
              </p>
            ) : null}
          </div>
      )}

      {as != "select" &&  as != "date" && (
          <div className="relative ">
            <Field
              autoComplete="off"
              as={as}
              type="text"
              {...(btnSuccess ? { value: "", readOnly: true } : {})}
              className={`w-full block bg-zinc-100 outline-none box-border px-5 py-3 opensans-bold placeholder-gray-600 rounded-md max-w-lg lg:max-w-none  ${btnSuccess&& "pointer-events-none"}  ${extraClass} ${
                errors[name] && touched[name] && !btnSuccess
                  ? "shadow-sm shadow-red-600/80"
                  : "border-0"
              }`}
              placeholder={btnSuccess ?"¡Enviado!" : placeholder}
              name={name}
            />
            {errors[name] && touched[name] && !btnSuccess ? (
              <p className={`absolute ml-5 mt-1 text-xs font-bold ${!errorColor ? 'text-red-500' : errorColor}`}>
                {errors[name]}
              </p>
            ) : null}
          </div>
      )}
    </>
  );
};

FormFieldAndError.propTypes = {
  btnSuccess: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  name: PropTypes.any,
  placeholder: PropTypes.any,
  extraClass: PropTypes.any,
  as: PropTypes.any,
  options: PropTypes.array,
  errorColor: PropTypes.any,
};

export default FormFieldAndError;
