import React, { useState } from "react";
import FormFieldAndError from "./FormFieldAndError";
import axios from "axios";
import { Formik, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Image from "next/image";
import PropTypes from "prop-types";
export default function NewsletterCta({ bg = "rgb(0, 111, 185, 0.9)" }) {
  const [btnSuccess, setBtnSuccess] = useState(false);
  const [btnWait, setBtnWait] = useState(false);

  const post = (values) => {
    if (btnWait) {
      return toast.error("Sorry, something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const data = {
      mail: values.email,
    };
    setBtnWait(true);

    axios({
      method: "post",
      url: `${process.env.NEXT_PUBLIC_API}/api/v1/newsletter`,

      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.status == 201) {
          toast.error("Este correo ya ha sido registrado", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setBtnWait(false);
        } else {
          setBtnSuccess(true);
          toast.success("Correo Agregado con exito", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        setBtnWait(false);

        toast.error("Hubo un problema", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const ContactSchema = Yup.object().shape({
    email: Yup.string()
      .email("Debe ser un coreo valido")
      .required("Requerido")
      .min(5, "Debe tener al menos 5 caracteres")
      .max(125, "No debe tener mas de 125 caracteres"),
  });
  return (
    <>
      <section
        data-wow-duration="1s"
        className="relative flex items-center justify-center px-5 py-20 bg-no-repeat bg-cover animate__animated animate__fadeIn wow"
        style={{
          backgroundPosition: "50% 20%",
        }}
      >
        <div
          className="absolute z-20 w-full h-full"
          style={{ background: bg }}
        />
        <Image fill src={'/images/newsletterCta.jpg'} alt={'Newsletter'} className="object-cover object-center" />

        <ToastContainer
          position="top-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        <div
          data-wow-duration="1s"
          data-wow-delay="0.5s"
          className="relative z-20 w-full max-w-xl !mx-auto  md:max-w-5xl 2xl:max-w-7xl wow animate__animated animate__fadeIn"
        >
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={ContactSchema}
            onSubmit={post}
          >
            {({ errors, touched }) => (
              <Form
                action="#"
                noValidate
                className="flex flex-col justify-center md:items-center md:flex-row md:justify-between gap-x-2"
              >
                <div className="flex flex-col text-white">
                  <div className="max-w-xl">
                    <h2 className="relative z-10 mt-5 mb-5 2xl:max-w-3xl md:mt-0 text-center text-2xl md:text-left md:text-3xl font-bold wow animate__animated animate__fadeIn">
                      Suscríbete a nuestro Newsletter
                    </h2>
                    <p className="text-base text-center md:text-left max-w-prose">
                      Amplía tus conocimientos con nuestro boletín de recursos y
                      mantente actualizado con nuestro contenido para apoyar a tu
                      negocio.
                    </p>
                  </div>
                </div>
                <div className="flex justify-center gap-3 mt-5 md:mt-0">
                  <FormFieldAndError
                    btnSuccess={btnSuccess}
                    errors={errors}
                    touched={touched}
                    name={"email"}
                    placeholder="Email:"
                    errorColor={"text-red-200 mt-3 ml-0 text-lg"}
                    extraClass={"w-full md:w-[400px]"}
                  />
                  <button
                    type="submit"
                    id="contactBtn"
                    className={`relative inline-block text-sm text-white group focus:outline-none focus:ring w-fit ${btnSuccess && 'pointer-events-none brightness-75'}`}
                  >
                    <div className="relative flex items-center justify-center w-12 h-12 p-2 bg-white rounded-full hover:bg-gray-300 font-bold gap-x-2">
                      <Image
                        width={100}
                        height={100}
                        id="contactBtnIcon"
                        src="/icons/arrow-blue.svg"
                        className="object-contain w-6 h-6 "
                        alt="Newsletter CTA Icon"
                      />
                    </div>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
}

NewsletterCta.propTypes = {
  bg: PropTypes.any,
};