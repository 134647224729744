import Image from "next/image";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import { Autoplay } from "swiper";

export default function OurClients({ data }) {
  if (data && data.length) {
    return (
      <>
        <section data-wow-duration="1s" className="relative flex items-center justify-center px-5 py-10 animate__animated animate__fadeIn wow bg-gray-dark ">
          <div className="w-full max-w-xl mx-auto md:max-w-5xl 2xl:max-w-screen-2xl ">
            <div className="items-center md:grid clientsGrid gap-x-5">
              <div className="md:w-[300px] flex flex-col items-center justify-center md:items-start ">
                <p
                  className="max-w-sm mb-5 text-base text-center text-white md:text-lg md:mb-10 md:text-left"
                >
                  Algunos de nuestros clientes, quienes han colocado su
                  confianza en nuestro trabajo.
                </p>
                <a
                  className="relative inline-block mb-10 text-sm font-medium text-blue-light group focus:outline-none focus:ring w-fit md:mb-0"
                  href="#contacto"
                >
                  <span className="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-white rounded-md group-hover:translate-y-2 group-hover:translate-x-2" />
                  <span className="relative flex items-center px-8 py-3 rounded-md font-bold bg-yellow-dark gap-x-2">
                    Solicitar servicio
                    <Image
                      width={200}
                      height={200}
                      src="/icons/arrow-blue.svg"
                      className="object-contain w-6 h-6"
                      alt="Flecha"
                    />
                  </span>
                </a>
              </div>

              <div className="">
                <Swiper
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: true,
                  }}
                  // grabCursor={true}
                  breakpoints={{
                    0: {
                      slidesPerView: 2,
                    },
                    400: {
                      slidesPerView: 3,
                    },

                    767: {
                      slidesPerView: 5,
                    },
                  }}
                  modules={[Autoplay]}
                  className="swiper clientsSlider"
                >
                  {data.map((item, index) => (
                    <SwiperSlide
                      key={index}
                      data-wow-duration="1s"
                      className="swiper-slide wow animate__animated animate__fadeIn "
                    >
                      <div className="flex items-center justify-center p-2 transition-all duration-200 rounded-lg ">
                        <Image
                          width={500}
                          height={500}
                          src={item.image}
                          className="object-contain p-1 bg-white border border-gray-200 rounded-xl w-28 h-28"
                          alt={item.name}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

OurClients.propTypes = {
  data: PropTypes.any,
};